
.icon-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    height: 40px;
    width: 40px;
    border: 1px solid #8f8f8f;
    /* background-color: #00000036; */
    border-radius: 20%;
}

.icon-button:hover {
    background-color: #00000007;
    cursor: pointer;
}