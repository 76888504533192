.end-screen {
    /* background-color: grey; */
    text-align: center;
    font-family: 'Syne Mono', monospace;
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
}

.final-score {
    font-size: 40px;
    font-weight: bold;
    margin: 10px;
}
