.letter-box {
    border: 1px solid black;
    display: inline-block;
    width: 55px;
    height: 55px;
    vertical-align: middle;
    background-color: rgb(255, 255, 255);
}

.letter-box-container {
    display: inline-block;
    vertical-align: bottom;
    margin: 1px;
    padding: 0;
    width: 60px;
    height: 60px;
}

.letter-box span {
    display: inline-block;
    margin: 0;
    padding: 0;
}

.shadow {
    background-color: rgba(163, 163, 163, 0.425);
}