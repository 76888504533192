@import url('https://fonts.googleapis.com/css2?family=Syne+Mono&display=swap');

.clock {
    font-size: 20px;
}

.clock-body {
    margin: auto;
    height: 60px;
    width: 60px;
    background-color: #000000;
    border-radius: 50%;
    display: flex;
    align-items: center;
    /* Vertically center the inner div */
    justify-content: center;
    /* Optionally center horizontally */
    text-align: center;
    align-items: center;
}

.clock-face {
    height: 50px;
    width: 50px;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    display: flex;
    align-items: center;
    /* Vertically center the inner div */
    justify-content: center;
    /* Optionally center horizontally */
}

.alert-text {
    color: rgb(255, 0, 0);
}

.label {
    font-size: 16px;
}