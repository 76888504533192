
.landing-screen {
    /* background-color: aquamarine; */
    text-align: center;
}

.wizard {
    height: 180px;
    display: block;
    /* background-color: blue; */
    margin: auto;
    margin-bottom: 0px;
}