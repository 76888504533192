
.stats-block {
    /* background-color: tan; */
    text-align: center;
    font-size: 16px;
}


.val-col {
    width: 20px;
}
