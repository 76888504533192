.game-board {
    /* background-color: azure; */
}

.client-letters {
    font-size: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 50px 0;
    padding: 0;
}

.continue-button-container {
    /* background-color: blueviolet; */
    text-align: center;
}

.game-board-header {
    display: flex;
    justify-content: space-between;
    align-items: top;
    /* background-color: rgba(248, 237, 252, 0.5); */
    height: 50px;
    font-family: 'Syne Mono', monospace;
    font-weight: bold;
    margin-bottom: 30px;
}

.recap-container {
    /* background-color: blueviolet; */
    width: 250px;
    display: flex;
    justify-content: right;
    /* margin: -6px 0; */
}

.clock-container {
    /* background-color: red; */
    margin-top: -6px;
}

.score-update-container {
    /* background-color: green; */
    font-size: 22px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    /* background-color: red; */
    font-size: 36px;
}